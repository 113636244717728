import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import {
  Avatar,
  Col,
  Drawer,
  Empty,
  Form,
  Input,
  Pagination,
  Row,
  Select,
  Spin,
  Switch,
  Table,
  Tag,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { companyBlock, companyLists, newCompanyApplies } from "../../api/auth";
import { CompanyDataView } from "../../components/companyDataView";
import { IMAGE_PATH } from "../../constants";
const { Search } = Input;

export const Company = () => {
  const [companiesList, setCompaniesList] = useState([]);
  const [viewData, setViewData] = useState({});
  const [requireds, setRequireds] = useState({
    search: false,
  });
  const [checked, setChecked] = useState(Boolean);
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState(null);
  const [blocked, setBlocked] = useState(null);
  const [update, setUpdate] = useState(false);
  const [meta, setMeta] = useState();
  const [open, setOpen] = useState(false);
  const [params, setParams] = useState({
    page: 1,
    size: 10,
    state,
    blocked,
  });

  const handlerPag = (e) => {
    setParams((prevState) => ({
      ...prevState,
      page: e,
    }));
  };
  const showDrawer = (item) => {
    setOpen(true);
    setViewData(item);
  };
  const onClose = () => {
    setOpen(false);
  };
  const [paginationMeta, setPaginationMeta] = useState({
    size: 10,
    page: 1,
    totalCount: 1,
    totalPage: 1,
  });
  const [current, setCurrent] = useState(1);
  const onChange = (page) => {
    console.log(page);
    setCurrent(page);
    setPaginationMeta({ size: 10, page: page, totalCount: 1, totalPage: 1 });
  };
  const isBlocked = async (id, checked, inx) => {
    try {
      setLoading(true);
      const res = await companyBlock(id, checked);
      message.success(res?.data?.message);
      companiesList[inx].isBlocked = checked;
    } catch (error) {
      message.error(error?.data?.message);
      console.log("🚀 ~ file: index.jsx:62 ~ isBlocked ~ error:", error);
    } finally {
      setLoading(false);
      setChecked(checked);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const res = await newCompanyApplies(params);
        const data = res?.data?.data;

        setCompaniesList(data?.items);
        setMeta(data?.meta);
        console.log("🚀 ~ file: index.jsx:70 ~ data:", data);
      } catch (error) {
        console.log("🚀 ~ file: index.jsx:70 ~ error:", error);
      } finally {
        setLoading(false);
      }
    })();
  }, [params]);

  const onFinish = async (values) => {
    console.log("🚀 ~ file: index.jsx:57 ~ onFinish ~ values:", values);
  };

  const handleChangeCompanyState = (value) => {
    setParams((oldState) => ({
      ...oldState,
      state: value ? value : null,
    }));
  };

  const handleChangeCompanyBlocked = (value) => {
    setParams((oldState) => ({
      ...oldState,
      blocked: value ? value : null,
    }));
  };

  const onSearch = (value) => {
    if (value) {
      setParams((oldState) => ({
        ...oldState,
        any: value,
        page: 1,
      }));
    } else {
      setRequireds((prevState) => ({
        ...prevState,
        search: true,
      }));
    }
  };

  const onSearchChange = (e) => {
    const value = e?.target?.value;

    if (!value) {
      setParams((oldState) => ({
        ...oldState,
        any: null,
      }));
    } else {
      setRequireds((prevState) => ({
        ...prevState,
        search: false,
      }));
    }
  };

  return (
    <div>
      <div>
        <div>
          <h2 className="text-2xl font-semibold leading-tight">Companies</h2>
        </div>
        <div>
          <h2 className="text-2xl font-semibold leading-tight text-center">
            Filters
          </h2>
        </div>
        <Form layout="vertical" onFinish={onFinish}>
          <Row>
            {/* State */}
            <Col span={2}>
              <Form.Item label="State" name={"state"}>
                <Select
                  defaultValue=""
                  style={{ width: 120 }}
                  onChange={handleChangeCompanyState}
                  options={[
                    { value: "", label: "All" },
                    { value: "CONFIRMED", label: "Confirmed" },
                    { value: "NEW", label: "New" },
                  ]}
                />
              </Form.Item>
            </Col>

            {/* Is blocked */}
            <Col span={2}>
              <Form.Item label="Blocked" name={"blocked"}>
                <Select
                  defaultValue=""
                  style={{ width: 100 }}
                  onChange={handleChangeCompanyBlocked}
                  options={[
                    { value: "", label: "All" },
                    { value: true, label: "Blocked" },
                    { value: false, label: "None blocked" },
                  ]}
                />
              </Form.Item>
            </Col>

            {/* Search */}
            <Col span={20}>
              <Form.Item
                label="Please enter comapny name or email"
                name={"searchAny"}
                style={{ display: "flex", justifyContent: "end" }}
                help={requireds.search ? "Please fill the field!" : ""}
                validateStatus={requireds.search ? "warning" : ""}
              >
                <Search
                  style={{ width: 250 }}
                  placeholder="Please enter company name or email"
                  onSearch={onSearch}
                  onChange={(e) => onSearchChange(e)}
                  onBlur={() =>
                    setRequireds((prevState) => ({
                      ...prevState,
                      search: false,
                    }))
                  }
                  enterButton
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <div className="py-4 overflow-x-auto">
          <div className="inline-block min-w-full shadow-md rounded-lg overflow-hidden">
            <Spin spinning={loading}>
              <table className="min-w-full leading-normal">
                <thead>
                  <tr>
                    <th className="py-3 px-5 text-left border-b-2 border-gray-200 bg-gray-100">
                      №
                    </th>
                    <th className="py-3 px-5 text-left border-b-2 border-gray-200 bg-gray-100">
                      Name/photo
                    </th>
                    <th className="py-3 px-5 text-left border-b-2 border-gray-200 bg-gray-100">
                      Email
                    </th>
                    <th className="py-3 px-5 text-left border-b-2 border-gray-200 bg-gray-100">
                      Link
                    </th>
                    <th className="py-3 px-5 text-left border-b-2 border-gray-200 bg-gray-100">
                      Phones
                    </th>
                    <th className="py-3 px-5 text-left border-b-2 border-gray-200 bg-gray-100">
                      State
                    </th>
                    <th className="py-3 px-5 text-left border-b-2 border-gray-200 bg-gray-100">
                      Is blocked
                    </th>
                    <th className="py-3 px-5 text-left border-b-2 border-gray-200 bg-gray-100">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="relative">
                  {companiesList?.length ? (
                    companiesList?.map((item, inx) => (
                      <tr key={inx}>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <p className="text-gray-900 whitespace-no-wrap">
                            {params?.page === 1
                              ? inx + 1
                              : params?.page * 10 - 10 + inx + 1}
                          </p>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <div className="flex items-center">
                            <div className="flex-shrink-0 w-10 h-10">
                              <img
                                className="w-full h-full rounded-full imgFluidShadow"
                                src={
                                  item?.logo
                                    ? `${IMAGE_PATH + "/" + item?.logo}`
                                    : "https://forcebrands.com/assets/fallback/company-default-4549373b79625823b56e48c7918608f77be903ad2fd38cfc9b6929d095994013.png"
                                }
                                alt={item?.name}
                              />
                            </div>
                            <div className="ml-3">
                              <p className="text-gray-900 whitespace-no-wrap">
                                {item?.name}
                              </p>
                            </div>
                          </div>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <p className="text-gray-900 whitespace-no-wrap">
                            <a href={`mailto:${item?.email}`}>{item?.email}</a>
                          </p>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <p className="text-gray-900 whitespace-no-wrap">
                            <a target="_blank" href={item?.website}>
                              {item?.website}
                            </a>
                          </p>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <p className="text-gray-900 whitespace-no-wrap">
                            {item?.phones?.split(",")?.map((phone, inx) => (
                              <a
                                key={inx}
                                className="block mb-1"
                                href={`tel:${phone}`}
                              >
                                <Tag color="#55acee">{phone}</Tag>
                              </a>
                            ))}
                          </p>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <p className="text-gray-900 whitespace-no-wrap">
                            <Tag color="#3b5999">{item?.state}</Tag>
                          </p>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <p className="text-gray-900 whitespace-no-wrap">
                            <Switch
                              className="bg-gray-400"
                              defaultChecked={item?.isBlocked}
                              checked={item?.isBlocked}
                              onChange={(e) =>
                                isBlocked(item?.id, e.valueOf(), inx)
                              }
                            />
                          </p>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <div className="flex ">
                            <EyeOutlined
                              onClick={() => showDrawer(item)}
                              className="text-green-500 text-2xl cursor-pointer hover:text-green-600 hover:transition-all"
                            />
                            {/* <DeleteOutlined className="text-red-500 text-2xl mr-2 cursor-pointer hover:text-red-600 hover:transition-all" /> */}
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <Empty
                      className="py-10"
                      style={{
                        position: "sticky",
                        left: "50%",
                        transform: "translateX(-50%)",
                      }}
                    />
                  )}
                </tbody>
              </table>
            </Spin>
          </div>
        </div>
        {/* Pagination */}
        {companiesList?.length ? (
          <div className="flex justify-center">
            <Pagination
              current={params.page}
              total={meta?.totalCount}
              pageSize={meta?.size}
              onChange={handlerPag}
            />
          </div>
        ) : (
          ""
        )}
      </div>
      <Drawer
        title="Company view"
        placement="right"
        onClose={onClose}
        open={open}
        width={600}
      >
        <CompanyDataView item={viewData} />
      </Drawer>
    </div>
  );
};
