import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import {
  Avatar,
  Col,
  Drawer,
  Empty,
  Form,
  Input,
  Pagination,
  Row,
  Select,
  Spin,
  Switch,
  Table,
  Tag,
  message,
} from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import {
  companyBlock,
  companyLists,
  getUserById,
  getUsers,
  newCompanyApplies,
  userBlock,
} from "../../api/auth";
import { CompanyDataView } from "../../components/companyDataView";
import { IMAGE_PATH } from "../../constants";
const { Search } = Input;

export const Users = () => {
  const [companiesList, setCompaniesList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingById, setLoadingById] = useState(true);
  const [requireds, setRequireds] = useState({
    search: false,
  });
  const [checked, setChecked] = useState(Boolean);
  const [userViewData, setUserViewData] = useState([]);
  const [disable, setDisable] = useState(false);
  const [update, setUpdate] = useState(false);
  const [open, setOpen] = useState(false);
  const [meta, setMeta] = useState();
  const [params, setParams] = useState({
    page: 1,
    size: 10,
  });

  const getUserByIdFunc = async (id) => {
    try {
      setLoadingById(true);
      const res = await getUserById(id);
      const data = res?.data?.data;
      setUserViewData(data);
      console.log("🚀 ~ file: index.jsx:72 ~ getJobById ~ data:", data);
    } catch (error) {
      console.log("🚀 ~ file: index.jsx:72 ~ getJobById ~ error:", error);
    } finally {
      setLoadingById(false);
    }
  };

  const showDrawer = (id) => {
    setOpen(true);
    getUserByIdFunc(id);
  };
  const onClose = () => {
    setOpen(false);
  };

  const handlerPag = (e) => {
    setParams((prevState) => ({
      ...prevState,
      page: e,
    }));
  };

  const isBlocked = async (id, checked, inx) => {
    try {
      setLoading(true);
      const res = await userBlock(id, checked);
      companiesList[inx].isBlocked = checked;
      message.success(res?.data?.message);
      // setUpdate((prev) => !prev);
    } catch (error) {
      console.log("🚀 ~ file: index.jsx:58 ~ isBlocked ~ error:", error);
      message.error(error?.data?.message);
    } finally {
      setChecked(checked);
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const res = await getUsers(params);
        const data = res?.data?.data;
        setCompaniesList(data?.items);
        console.log("🚀 ~ file: index.jsx:52 ~ data:", companiesList);
        setMeta(data?.meta);
      } catch (error) {
        console.log("🚀 ~ file: index.jsx:43 ~ error:", error);
      } finally {
        setLoading(false);
      }
    })();
  }, [params]);

  const onFinish = async (values) => {
    console.log("🚀 ~ file: index.jsx:57 ~ onFinish ~ values:", values);
  };

  const handleChangeUserType = (value) => {
    setParams((oldState) => ({
      ...oldState,
      userType: value ? value : null,
    }));
  };

  const handleChangeUserBlocked = (value) => {
    setParams((oldState) => ({
      ...oldState,
      isBlocked: value ? value : null,
    }));
  };

  const handleChangeUserVerifed = (value) => {
    setParams((oldState) => ({
      ...oldState,
      isVerified: value ? value : null,
    }));
  };

  const onSearch = (value) => {
    if (value) {
      setParams((oldState) => ({
        ...oldState,
        searchAny: value,
        page: 1,
      }));
    } else {
      setRequireds((prevState) => ({
        ...prevState,
        search: true,
      }));
    }
  };
  const onSearchChange = (e) => {
    const value = e?.target?.value;

    if (!value) {
      setParams((oldState) => ({
        ...oldState,
        searchAny: null,
      }));
    } else {
      setRequireds((prevState) => ({
        ...prevState,
        search: false,
      }));
    }
  };

  return (
    <div>
      <div>
        <div>
          <h2 className="text-2xl font-semibold leading-tight">Users</h2>
        </div>
        <div>
          <h2 className="text-2xl font-semibold leading-tight text-center">
            Filters
          </h2>
        </div>
        <Form layout="vertical" onFinish={onFinish}>
          <Row>
            {/* User type */}
            <Col span={2}>
              <Form.Item label="User type" name={"userType"}>
                <Select
                  defaultValue=""
                  style={{ width: 120 }}
                  onChange={handleChangeUserType}
                  options={[
                    { value: "", label: "All" },
                    { value: "STUDENT", label: "Student" },
                    { value: "ADMIN", label: "Admin" },
                  ]}
                />
              </Form.Item>
            </Col>

            {/* Is blocked */}
            <Col span={2}>
              <Form.Item label="Blocked" name={"blocked"}>
                <Select
                  defaultValue=""
                  style={{ width: 100 }}
                  onChange={handleChangeUserBlocked}
                  options={[
                    { value: "", label: "All" },
                    { value: true, label: "Blocked" },
                    { value: false, label: "None blocked" },
                  ]}
                />
              </Form.Item>
            </Col>

            {/* Is verified */}
            <Col span={2}>
              <Form.Item label="Verified" name={"verified"}>
                <Select
                  defaultValue=""
                  style={{ width: 100 }}
                  onChange={handleChangeUserVerifed}
                  options={[
                    { value: "", label: "All" },
                    { value: true, label: "Verified" },
                    { value: false, label: "None verified" },
                  ]}
                />
              </Form.Item>
            </Col>

            {/* Search */}
            <Col span={18}>
              <Form.Item
                label="Please enter user full name or email"
                name={"searchAny"}
                style={{ display: "flex", justifyContent: "end" }}
                help={requireds.search ? "Please fill the field!" : ""}
                validateStatus={requireds.search ? "warning" : ""}
              >
                <Search
                  style={{ width: 250 }}
                  placeholder="Pleace enter user name"
                  onSearch={onSearch}
                  onChange={(e) => onSearchChange(e)}
                  onBlur={() =>
                    setRequireds((prevState) => ({
                      ...prevState,
                      search: false,
                    }))
                  }
                  enterButton
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <div className="py-4 overflow-x-auto">
          <div className="inline-block min-w-full shadow-md rounded-lg overflow-hidden">
            <Spin spinning={loading}>
              <table className="min-w-full leading-normal">
                <thead>
                  <tr>
                    <th className="py-3 px-5 text-left border-b-2 border-gray-200 bg-gray-100">
                      #
                    </th>
                    <th className="py-3 px-5 text-left border-b-2 border-gray-200 bg-gray-100">
                      Name/photo
                    </th>
                    <th className="py-3 px-5 text-left border-b-2 border-gray-200 bg-gray-100">
                      Email
                    </th>
                    <th className="py-3 px-5 text-left border-b-2 border-gray-200 bg-gray-100">
                      Birthday
                    </th>
                    <th className="py-3 px-5 text-left border-b-2 border-gray-200 bg-gray-100">
                      UserType
                    </th>
                    <th className="py-3 px-5 text-left border-b-2 border-gray-200 bg-gray-100">
                      Phone number
                    </th>
                    <th className="py-3 px-5 text-left border-b-2 border-gray-200 bg-gray-100">
                      Institution name
                    </th>
                    <th className="py-3 px-5 text-left border-b-2 border-gray-200 bg-gray-100">
                      Is blocked
                    </th>
                    <th className="py-3 px-5 text-left border-b-2 border-gray-200 bg-gray-100">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {companiesList?.length ? (
                    companiesList?.map((item, inx) => (
                      <tr key={inx}>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <p className="text-gray-900 whitespace-no-wrap">
                            {params.page === 1
                              ? inx + 1
                              : params.page * 10 - 10 + inx + 1}
                          </p>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <div className="flex items-center">
                            <div className="flex-shrink-0 w-10 h-10">
                              <img
                                className="w-full h-full rounded-full imgFluidShadow"
                                src={
                                  item?.photoUrl
                                    ? item?.photoUrl?.startsWith("https")
                                      ? item?.photoUrl
                                      : IMAGE_PATH + item?.photoPath
                                    : "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                                }
                                alt={item?.name}
                              />
                            </div>
                            <div className="ml-3">
                              <p className="text-gray-900 whitespace-no-wrap">
                                {item?.fullname}
                              </p>
                            </div>
                          </div>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <p className="text-gray-900 whitespace-no-wrap">
                            <a href={`mailto:${item?.email}`}>{item?.email}</a>
                          </p>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <p className="text-gray-900 whitespace-no-wrap">
                            {item?.birthday &&
                              dayjs(item?.birthday).format("DD-MM-YYYY")}
                          </p>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <p className="text-gray-900 whitespace-no-wrap">
                            {item?.userType}
                          </p>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          {item?.phoneNumber && (
                            <a
                              key={inx}
                              className="block mb-1"
                              href={`tel:${item?.phoneNumber}`}
                            >
                              <Tag color="#55acee">{item?.phoneNumber}</Tag>
                            </a>
                          )}
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <p className="text-gray-900 whitespace-no-wrap">
                            {item?.institutionName}
                          </p>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <p className="text-gray-900 whitespace-no-wrap">
                            <Switch
                              className="bg-gray-400"
                              defaultChecked={item?.isBlocked}
                              checked={item?.isBlocked}
                              onChange={(e) =>
                                isBlocked(item?.id, e.valueOf(), inx)
                              }
                            />
                          </p>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <div className="flex ">
                            <EyeOutlined
                              onClick={() => showDrawer(item?.id)}
                              className="text-green-500 text-2xl cursor-pointer hover:text-green-600 hover:transition-all"
                            />
                            {/* <DeleteOutlined className="text-red-500 text-2xl mr-2 cursor-pointer hover:text-red-600 hover:transition-all" /> */}
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <Empty
                      className="py-10"
                      style={{
                        position: "sticky",
                        left: "50%",
                        transform: "translateX(-50%)",
                      }}
                    />
                  )}
                </tbody>
              </table>
            </Spin>
          </div>
        </div>
        {/* Pagination */}
        {companiesList?.length ? (
          <div className="flex justify-center">
            <Pagination
              current={params.page}
              total={meta?.totalCount}
              pageSize={meta?.size}
              onChange={handlerPag}
            />
          </div>
        ) : (
          ""
        )}
      </div>
      <Drawer
        title="Job details"
        placement="right"
        onClose={onClose}
        open={open}
        width={600}
      >
        {loadingById ? (
          <Spin
            className="py-10"
            style={{
              position: "sticky",
              left: "50%",
              transform: "translateX(-50%)",
            }}
          />
        ) : (
          <Row gutter={24}>
            <Col sm={24}>
              <div className="flex py-4">
                <div className="flex-shrink-0 w-15 h-10">
                  <img
                    className="w-full h-full rounded-full imgFluidShadow"
                    src={
                      userViewData?.photoUrl
                        ? userViewData?.photoUrl?.startsWith("https")
                          ? userViewData?.photoUrl
                          : IMAGE_PATH + userViewData?.photoPath
                        : "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                    }
                    alt={userViewData?.name}
                  />
                </div>
                <div className="ml-3">
                  <p className="text-gray-900 whitespace-no-wrap text-xl">
                    {userViewData?.fullname}
                  </p>
                </div>
              </div>
            </Col>
            <Col sm={24}>
              <div className="py-4">
                <h3 className="text-xl font-medium">About me</h3>
                <p className="text-gray-900 whitespace-no-wrap">
                  {userViewData?.aboutMe}
                </p>
              </div>
            </Col>
            <Col sm={12}>
              <div className="py-4">
                <h3 className="text-xl font-medium">Birthday</h3>
                <p className="text-gray-900 whitespace-no-wrap">
                  {userViewData?.birthday}
                </p>
              </div>
            </Col>
            <Col sm={12}>
              <div className="py-4">
                <h3 className="text-xl font-medium">Email</h3>
                <p className="text-gray-900 whitespace-no-wrap">
                  <a href={`mailto:${userViewData?.email}`}>
                    {userViewData?.email}
                  </a>
                </p>
              </div>
            </Col>
            <Col sm={12}>
              <div className="py-4">
                <h3 className="text-xl font-medium">District name</h3>
                <p className="text-gray-900 whitespace-no-wrap">
                  {userViewData?.districtName && userViewData?.districtName}
                </p>
              </div>
            </Col>
            <Col sm={12}>
              <div className="py-4">
                <h3 className="text-xl font-medium">Status</h3>
                <p className="text-gray-900 whitespace-no-wrap">
                  {userViewData?.isBlocked ? "Blocked" : "Not blocked"}
                </p>
              </div>
            </Col>
            <Col sm={12}>
              <div className="py-4">
                <h3 className="text-xl font-medium">Gender</h3>
                <p className="text-gray-900 whitespace-no-wrap">
                  {userViewData?.isMale ? "Male" : "Female"}
                </p>
              </div>
            </Col>
            <Col sm={12}>
              <div className="py-4">
                <h3 className="text-xl font-medium">Is verified</h3>
                <p className="text-gray-900 whitespace-no-wrap">
                  {userViewData?.isVerified ? "verified" : "Not verified"}
                </p>
              </div>
            </Col>
            <Col sm={12}>
              <div className="py-4">
                <h3 className="text-xl font-medium">Phone number</h3>
                {userViewData?.phoneNumber && (
                  <p className="text-gray-900 whitespace-no-wrap">
                    <a
                      className="block mb-1"
                      href={`tel:${userViewData?.phone}`}
                    >
                      <Tag color="#55acee">{userViewData?.phone}</Tag>
                    </a>
                  </p>
                )}
              </div>
            </Col>
            <Col sm={12}>
              <div className="py-4">
                <h3 className="text-xl font-medium">Region name</h3>
                <p className="text-gray-900 whitespace-no-wrap">
                  {userViewData?.regionName && userViewData?.regionName}
                </p>
              </div>
            </Col>
            <Col sm={12}>
              <div className="py-4">
                <h3 className="text-xl font-medium">User type</h3>
                {userViewData?.userType && (
                  <p className="text-gray-900 whitespace-no-wrap">
                    <Tag color="#3b5999">{userViewData?.userType}</Tag>
                  </p>
                )}
              </div>
            </Col>
            <Col sm={12}>
              <div className="py-4">
                <h3 className="text-xl font-medium">User name</h3>
                {userViewData?.username && (
                  <p className="text-gray-900 whitespace-no-wrap">
                    {userViewData?.username}
                  </p>
                )}
              </div>
            </Col>
          </Row>
        )}
      </Drawer>
    </div>
  );
};
