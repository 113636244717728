import React from "react";
import { Button, Col, Form, Row, Tag } from "antd";
import TextArea from "antd/es/input/TextArea";
import { IMAGE_PATH } from "../../constants";

export const CompanyDataView = ({ item }) => {
  console.log(item);
  return (
    <Row gutter={24}>
      <Col sm={12}>
        <div className="flex  py-4">
          <div className="flex-shrink-0 w-15 h-10">
            <img
              className="w-full h-full rounded-full imgFluidShadow"
              src={
                item?.logo
                  ? `${IMAGE_PATH + "/" + item?.logo}`
                  : "https://forcebrands.com/assets/fallback/company-default-4549373b79625823b56e48c7918608f77be903ad2fd38cfc9b6929d095994013.png"
              }
              alt={item?.name}
            />
          </div>
          <div className="ml-3">
            <p className="text-gray-900 whitespace-no-wrap text-xl">
              {item?.name}
            </p>
          </div>
        </div>
        <div className="py-4 mt-2">
          <h3 className="text-xl font-medium">Web site</h3>
          <p className="text-gray-900 whitespace-no-wrap">
            <a target="_blank" href={item?.website}>
              {item?.website}
            </a>
          </p>
        </div>
      </Col>
      <Col sm={12}>
        <div className="py-4">
          <h3 className="text-xl font-medium">Email</h3>
          <p className="text-gray-900 whitespace-no-wrap">
            <a href={`mailto:${item?.email}`}>{item?.email}</a>
          </p>
        </div>
        <div className="py-4">
          <h3 className="text-xl font-medium">Address</h3>
          <p className="text-gray-900 whitespace-no-wrap">{item?.address}</p>
        </div>
      </Col>
      <Col sm={12}>
        <div className="py-4">
          <h3 className="text-xl font-medium">Status</h3>
          <p className="text-gray-900 whitespace-no-wrap">
            <Tag color="#3b5999">{item?.state}</Tag>
          </p>
        </div>
      </Col>
      <Col sm={12}>
        <div className="py-4">
          <h3 className="text-xl font-medium">Register date</h3>
          <p className="text-gray-900 whitespace-no-wrap">
            {new Date(item?.createdAt)?.toLocaleString("en-GB", {
              hour12: false,
            })}
          </p>
        </div>
      </Col>
      <Col sm={24}>
        <div className="py-4">
          <h3 className="text-xl font-medium">Description</h3>
          <p className="text-gray-900 whitespace-no-wrap">
            {item?.description}
          </p>
        </div>
      </Col>
      <Col sm={24}>
        <div className="py-4">
          <h3 className="text-xl font-medium">Phone</h3>
          <p className="text-gray-900 whitespace-no-wrap">
            {item?.phones?.split(",")?.map((phone, inx) => (
              <a key={inx} className="block mb-1" href={`tel:${phone}`}>
                <Tag color="#55acee">{phone}</Tag>
              </a>
            ))}
          </p>
        </div>
      </Col>
    </Row>
  );
};
