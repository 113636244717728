import instance from "../../config";

// registration apies
const postLogin = (data) => {
  return instance({
    method: "post",
    url: "/auth/login?user_type=ADMIN",
    data: data,
  });
};

const aboutMe = (token) => {
  return instance({
    headers: {
      Authorization: `Bearer ${token}`,
    },
    method: "get",
    url: "/user/get-about",
  });
};

// Get users
const getUsers = (params) => {
  return instance({
    url: `/admin/users`,
    params: params,
  });
};
// Get user by id
const getUserById = (id) => {
  return instance({
    url: `/admin/users/${id}`,
  });
};
// user block
const userBlock = (id, value) => {
  return instance({
    method: "put",
    url: `/admin/users/${id}/block?isBlocked=${value}`,
  });
};

// statistic data
const staticticaData = () => {
  return instance({
    method: "get",
    url: `/admin/company/get-statistic`,
  });
};
// new company applies
const newCompanyApplies = (params) => {
  return instance({
    method: "get",
    url: `/admin/company`,
    params: params,
  });
};
// new company applies
const companyLists = (page, size) => {
  return instance({
    method: "get",
    url: `/admin/company/get-company-statuses?page=${page}&size=${size}`,
  });
};
// company block
const companyBlock = (id, value) => {
  return instance({
    method: "post",
    url: `/admin/company/set-blocked/${id}?blocked=${value}`,
  });
};
// get Areas all
const getAreasAll = (params) => {
  return instance({
    method: "get",
    url: `/admin/area/get-all`,
    params: params,
  });
};
// area archive
const areaArchive = (id, value) => {
  return instance({
    method: "put",
    url: `/admin/area/archived/${id}?archive=${value}`,
  });
};
// area delete
const areaDelete = (id) => {
  return instance({
    method: "delete",
    url: `/admin/area/delete/${id}`,
  });
};
// area edit
const areaEdit = (id, data) => {
  return instance({
    method: "put",
    url: `/admin/area/edit/${id}`,
    data: data,
  });
};
// area add
const areaAdd = (data) => {
  return instance({
    method: "post",
    url: `/admin/area`,
    data: data,
  });
};
// get Skills all
const getSkillsAll = (params) => {
  return instance({
    method: "get",
    url: `/admin/skill/get-all`,
    params: params,
  });
};
// skill edit
const skillEdit = (id, data) => {
  return instance({
    method: "put",
    url: `/admin/skill/edit/${id}`,
    data: data,
  });
};
// skill add
const skillAdd = (data) => {
  return instance({
    method: "post",
    url: `/admin/skill`,
    data: data,
  });
};
// skill delete
const skillDelete = (id) => {
  return instance({
    method: "delete",
    url: `/admin/skill/delete/${id}`,
  });
};
// skill archive
const skillArchive = (id, value) => {
  return instance({
    method: "put",
    url: `/admin/skill/archived/${id}?archive=${value}`,
  });
};
// confirm company
const confirmCompany = (id, type) => {
  return instance({
    method: "put",
    url: `/admin/company/confirmation/${id}?confirmStatus=${type}`,
  });
};
// unconfirm company
const uconfirmCompany = (id, type, rejectMessage) => {
  return instance({
    method: "put",
    url: `/admin/company/confirmation/${id}?confirmStatus=${type}&rejectMessage=${rejectMessage}`,
  });
};
// get Sudents all
const getStudentsAll = (page, size) => {
  return instance({
    method: "get",
    url: `/admin/user?size=${size}&page=${page}&user_type=STUDENT`,
  });
};
// student block
const studentBlock = (id, value) => {
  return instance({
    method: "post",
    url: `/admin/user/blocked/${id}?block=${value}`,
  });
};

// get All jobs
const getAllJobs = (params) => {
  return instance({
    method: "get",
    url: `/admin/job-positions`,
    params: params,
  });
};

// get All company
const getAllComp = () => {
  return instance({
    method: "get",
    url: `/admin/company/select`,
  });
};
// get job by id
const getJobById = (id, params) => {
  return instance({
    method: "get",
    url: `/admin/job-positions/${id}?type=${params}`,
  });
};
// Job block
const vacanyBlock = (id, value) => {
  return instance({
    method: "put",
    url: `/admin/job-positions/${id}/archive?isArchived=${value}`,
  });
};
export {
  getAllComp,
  vacanyBlock,
  getUserById,
  userBlock,
  getUsers,
  getAllJobs,
  getJobById,
  postLogin,
  aboutMe,
  newCompanyApplies,
  companyLists,
  staticticaData,
  companyBlock,
  getAreasAll,
  areaArchive,
  areaDelete,
  areaAdd,
  areaEdit,
  uconfirmCompany,
  confirmCompany,
  getStudentsAll,
  studentBlock,
  getSkillsAll,
  skillDelete,
  skillEdit,
  skillAdd,
  skillArchive,
};
