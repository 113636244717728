import React, { useState } from "react";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import { Layout, Menu, theme } from "antd";
import "./style.css";
import { Outlet, useNavigate } from "react-router-dom";
import {
  HiBuildingLibrary,
  HiChatBubbleLeft,
  HiHome,
  HiShoppingBag,
  HiUserGroup,
} from "react-icons/hi2";

const { Header, Sider, Content } = Layout;

const Main = () => {
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const [current, setCurrent] = useState("1");
  const onClick = (e) => {
    console.log("click ", e);
    setCurrent(e.key);
    navigate(`${e.key}`);
  };
  return (
    <Layout>
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <h2 className="text-2xl py-3 font-bold text-white text-center">
          OneCruit
        </h2>
        <Menu
          onClick={onClick}
          theme="dark"
          mode="inline"
          defaultSelectedKeys={current}
          items={[
            {
              key: " ",
              icon: <HiHome />,
              label: "Home",
            },
            {
              key: "users",
              icon: <HiUserGroup />,
              label: "Users",
            },
            {
              key: "companies",
              icon: <HiBuildingLibrary />,
              label: "Companies",
            },
            {
              key: "jobs",
              icon: <HiShoppingBag />,
              label: "Jobs",
            },
            {
              key: "internships",
              icon: <HiShoppingBag />,
              label: "Internships",
            },
            // {
            //   key: "students",
            //   icon: <HiUserGroup />,
            //   label: "Students",
            // },
            {
              key: "areas",
              icon: <HiUserGroup />,
              label: "Areas",
            },
            {
              key: "skills",
              icon: <HiUserGroup />,
              label: "Skills",
            },
            // {
            //   key: "appeals",
            //   icon: <HiChatBubbleLeft />,
            //   label: "Appeals",
            // },
            // {
            //   key: "7",
            //   icon: <UploadOutlined />,
            //   label: "nav 3fdf",
            // },
          ]}
        />
      </Sider>
      <Layout className="site-layout">
        <Header
          style={{
            padding: 0,
            background: colorBgContainer,
          }}
        >
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger",
              onClick: () => setCollapsed(!collapsed),
            }
          )}
        </Header>
        <Content
          style={{
            margin: "24px 16px",
            padding: 24,
            minHeight: "88vh",
            background: colorBgContainer,
            overflowY: "auto",
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};
export default Main;
