import React, { useEffect, useState } from "react";
import { Button, Empty, Modal, Pagination, Spin, Table, Tag } from "antd";
import { AiOutlinePhone, AiOutlineUser } from "react-icons/ai";
import { HiOutlineBuildingLibrary, HiShoppingBag } from "react-icons/hi2";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  FieldTimeOutlined,
} from "@ant-design/icons";
import { RejectModal } from "../../components/rejectModal";
import { newCompanyApplies, staticticaData } from "../../api/auth";
import { IMAGE_PATH } from "../../constants";

export const Dashboard = () => {
  const [open, setOpen] = useState(false);
  const [modalOptions, setModalOptions] = useState({
    type: "",
    open: open,
    id: Number,
  });
  const [loading, setLoading] = useState(true);
  const [statistics, setStatistics] = useState({});
  const [companiesList, setCompaniesList] = useState([]);
  const [meta, setMeta] = useState();
  const [params, setParams] = useState({
    state: "NEW",
    page: 1,
    size: 10,
  });

  const handlerPag = (e) => {
    setParams((prevState) => ({
      ...prevState,
      page: e,
    }));
  };

  const showModal = (type, open, id) => {
    console.log("🚀 ~ file: index.jsx:21 ~ showModal ~ id:", id);
    setOpen(true);
    setModalOptions({
      type,
      open,
      id,
    });
  };
  const handleOk = () => {
    // setOpen(false);
  };
  const handleCancel = () => {
    setModalOptions({
      type: "",
      open: false,
      id: Number,
    });
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        let res = await newCompanyApplies(params);
        const data = res?.data?.data;

        setCompaniesList(data?.items);
        setMeta(res?.data?.data?.meta);
      } catch (error) {
        console.log("🚀 ~ file: index.jsx:60 ~ error:", error);
      } finally {
        setLoading(false);
      }
    })();
  }, [params]);

  useEffect(() => {
    (async () => {
      try {
        const res = await staticticaData();
        setStatistics(res?.data?.data);
      } catch (error) {
        console.log("🚀 ~ file: index.jsx:82 ~ error:", error);
      }
    })();
  }, []);

  return (
    <>
      <div className="flex lg:flex-nowrap flex-wrap  w-full gap-2 ">
        <div className="flex justify-between items-center bg-blue-500 w-full rounded-lg p-6 text-white text-3xl">
          <div className="flex items-center ">
            <AiOutlineUser className="font-medium" />
            <h3 className="mx-1 font-medium">Students</h3>
          </div>
          <h3>{statistics?.students}</h3>
        </div>
        <div className="flex justify-between items-center bg-green-500 w-full rounded-lg p-6 text-white text-3xl">
          <div className="flex items-center ">
            <HiOutlineBuildingLibrary />
            <h3 className="mx-1 font-medium">Companies</h3>
          </div>
          <h3>{statistics?.companies}</h3>
        </div>
        <div className="flex justify-between items-center bg-red-500 w-full rounded-lg p-6 text-white text-3xl">
          <div className="flex items-center ">
            <HiShoppingBag className="font-medium" />
            <h3 className="mx-1 font-medium">Jobs</h3>
          </div>
          <h3>{statistics?.jobs}</h3>
        </div>
        <div className="flex justify-between items-center bg-orange-500 w-full rounded-lg p-6 text-white text-3xl">
          <div className="flex items-center ">
            <HiShoppingBag className="font-medium" />
            <h3 className="mx-1 font-medium">Internships</h3>
          </div>
          <h3>{statistics?.internships}</h3>
        </div>
      </div>
      {/* <div className="w-full shadow-md p-2 my-3">
        <Table columns={columns} dataSource={data} size="middle" />
      </div> */}
      <div>
        <div className="py-8">
          <div>
            <h2 className="text-2xl font-semibold leading-tight">Applies</h2>
          </div>
          <div className="py-4 overflow-x-auto">
            <div className="inline-block min-w-full shadow-md rounded-lg overflow-hidden">
              <Spin spinning={loading}>
                <table className="min-w-full leading-normal">
                  <thead>
                    <tr>
                      <th className="px-5 py-3 text-left border-b-2 border-gray-200 bg-gray-100">
                        №
                      </th>
                      <th className="px-5 py-3 text-left border-b-2 border-gray-200 bg-gray-100">
                        Name
                      </th>
                      <th className="px-5 py-3 text-left border-b-2 border-gray-200 bg-gray-100">
                        Description
                      </th>
                      <th className="px-5 py-3 text-left border-b-2 border-gray-200 bg-gray-100">
                        Email
                      </th>
                      <th className="px-5 py-3 text-left border-b-2 border-gray-200 bg-gray-100">
                        Link
                      </th>
                      <th className="px-5 py-3 text-left border-b-2 border-gray-200 bg-gray-100">
                        Phones
                      </th>
                      <th className="px-5 py-3 text-left border-b-2 border-gray-200 bg-gray-100">
                        State
                      </th>
                      <th className="px-5 py-3 text-left border-b-2 border-gray-200 bg-gray-100">
                        Date
                      </th>
                      <th className="px-5 py-3 text-left border-b-2 border-gray-200 bg-gray-100">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {companiesList?.length ? (
                      companiesList.map((item, inx) => (
                        <tr key={inx}>
                          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                            <p className="text-gray-900 whitespace-no-wrap">
                              {params.page === 1
                                ? inx + 1
                                : params.page * 10 - 10 + inx + 1}
                            </p>
                          </td>
                          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                            <div className="flex items-center">
                              <div className="flex-shrink-0 w-10 h-10">
                                <img
                                  className="w-full h-full rounded-full"
                                  src={
                                    item?.photoUrl
                                      ? item?.photoUrl?.startsWith("https")
                                        ? item?.photoUrl
                                        : IMAGE_PATH + item?.photoPath
                                      : "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                                  }
                                  alt=""
                                />
                              </div>
                              <div className="ml-3">
                                <p className="text-gray-900 whitespace-no-wrap">
                                  {item?.name}
                                </p>
                              </div>
                            </div>
                          </td>
                          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                            <p className="text-gray-900 whitespace-no-wrap truncateW100">
                              {item?.description}
                            </p>
                          </td>
                          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                            <p className="text-gray-900 whitespace-no-wrap">
                              <a href={`mailto:${item?.email}`}>
                                {item?.email}
                              </a>
                            </p>
                          </td>
                          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                            <p className="text-gray-900 whitespace-no-wrap">
                              <a target="_blank" href={item?.email}>
                                {item?.email}
                              </a>
                            </p>
                          </td>
                          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                            <p className="text-gray-900 whitespace-no-wrap">
                              {item?.phones?.split(",")?.map((phone, inx) => (
                                <a
                                  key={inx}
                                  className="mb-1 block"
                                  href={`tel:${phone}`}
                                >
                                  <Tag color="#55acee">{phone}</Tag>
                                </a>
                              ))}
                            </p>
                          </td>
                          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                            <p className="text-gray-900 whitespace-no-wrap mb-1">
                              <Tag color="#3b5999">{item?.state}</Tag>
                            </p>
                          </td>
                          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                            <p className="text-gray-900 whitespace-no-wrap">
                              <>
                                <FieldTimeOutlined className="text-blue-600 text-2xl mr-2" />
                                <span>
                                  {new Date(item?.createdAt)?.toLocaleString(
                                    "en-GB",
                                    {
                                      hour12: false,
                                    }
                                  )}
                                </span>
                              </>
                            </p>
                          </td>
                          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                            <div className="flex ">
                              <CheckCircleOutlined
                                onClick={(e) =>
                                  showModal("approve", true, item?.id)
                                }
                                className="text-green-500 text-2xl mr-2 cursor-pointer hover:text-green-800 hover:transition-all"
                              />
                              <CloseCircleOutlined
                                onClick={() =>
                                  showModal("reject", true, item?.id)
                                }
                                className="text-red-500 text-2xl cursor-pointer hover:text-red-800 hover:transition-all"
                              />
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <Empty
                        className="py-10"
                        style={{
                          position: "sticky",
                          left: "50%",
                          transform: "translateX(-50%)",
                        }}
                      />
                    )}
                  </tbody>
                </table>
              </Spin>
            </div>
          </div>
          {/* Pagination */}
          {companiesList?.length ? (
            <div className="flex justify-center">
              <Pagination
                current={params.page}
                total={meta?.totalCount}
                pageSize={meta?.size}
                onChange={handlerPag}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <Modal
        open={modalOptions.open}
        title={modalOptions.type === "approve" ? "Approve" : "Reject appeal"}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
      >
        <RejectModal
          modalOptions={modalOptions}
          setModalOptions={setModalOptions}
          companiesList={companiesList}
          setCompaniesList={setCompaniesList}
        />
      </Modal>
    </>
  );
};
