import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import { Avatar, Drawer, Pagination, Switch, Table, Tag, message } from "antd";
import React, { useEffect, useState } from "react";
import {
  companyBlock,
  companyLists,
  getStudentsAll,
  newCompanyApplies,
  studentBlock,
} from "../../api/auth";
import { CompanyDataView } from "../../components/companyDataView";

export const Students = () => {
  const [studentsList, setStudentsList] = useState([]);
  const [viewData, setViewData] = useState({});
  const [checked, setChecked] = useState(Boolean);
  const [update, setUpdate] = useState(false);
  const [open, setOpen] = useState(false);

  const showDrawer = (item) => {
    setOpen(true);
    setViewData(item);
  };

  const onClose = () => {
    setOpen(false);
  };

  const [paginationMeta, setPaginationMeta] = useState({
    size: 10,
    page: 1,
    totalCount: 1,
    totalPage: 1,
  });

  const [current, setCurrent] = useState(1);

  const onChange = (page) => {
    console.log(page);
    setCurrent(page);
    setPaginationMeta({ size: 10, page: page, totalCount: 1, totalPage: 1 });
  };

  const isBlocked = (id, checked) => {
    console.log(`switch to ${checked}`);
    studentBlock(id, checked)
      .then((res) => {
        message.success(res?.data?.message);
        setUpdate((prev) => !prev);
      })
      .catch((err) => message.error(err?.data?.message));
    setChecked(checked);
  };

  const { page, size, totalCount, totalPage } = paginationMeta;

  useEffect(() => {
    let res = getStudentsAll(page, size)
      .then((res) => {
        setStudentsList(res?.data?.data?.items);
        console.log(res);
        setPaginationMeta(res?.data?.data?.meta);
      })
      .catch((err) => console.log(err));
  }, [checked, current, update]);

  console.log(studentsList);

  console.log(page, size, totalCount, totalPage);

  return (
    <div>
      <div>
        <div>
          <h2 className="text-2xl font-semibold leading-tight">Students</h2>
        </div>
        <div className="py-4 overflow-x-auto">
          <div className="inline-block min-w-full shadow-md rounded-lg overflow-hidden">
            <table className="min-w-full leading-normal">
              <thead>
                <tr>
                  <th className="py-3 border-b-2 border-gray-200 bg-gray-100">
                    #
                  </th>
                  <th className="py-3 border-b-2 border-gray-200 bg-gray-100">
                    Name/photo
                  </th>
                  <th className="py-3 border-b-2 border-gray-200 bg-gray-100">
                    Email
                  </th>
                  <th className="py-3 border-b-2 border-gray-200 bg-gray-100">
                    Birthday
                  </th>
                  <th className="py-3 border-b-2 border-gray-200 bg-gray-100">
                    Phones
                  </th>
                  <th className="py-3 border-b-2 border-gray-200 bg-gray-100">
                    About me
                  </th>
                  <th className="py-3 border-b-2 border-gray-200 bg-gray-100">
                    Is blocked
                  </th>
                  <th className="py-3 border-b-2 border-gray-200 bg-gray-100">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {studentsList?.map((item, inx) => (
                  <tr key={inx}>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <p className="text-gray-900 whitespace-no-wrap">
                        {inx + 1}
                      </p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <div className="flex">
                        <div className="flex-shrink-0 w-10 h-10">
                          <img
                            className="w-full h-full rounded-full"
                            src={`https://onecruit.uz/api/storage/files/${item?.photo?.pathFile}`}
                            alt={item?.name}
                          />
                        </div>
                        <div className="ml-3">
                          <p className="text-gray-900 whitespace-no-wrap">
                            {item?.fullname}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <p className="text-gray-900 whitespace-no-wrap">
                        <a href={`mailto:${item?.email}`}>{item?.email}</a>
                      </p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <p className="text-gray-900 whitespace-no-wrap">
                        {item?.birthday}
                        {/* <a target="_blank" href={item?.link}>
                          {item?.link}
                        </a> */}
                      </p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-center">
                      <p className="text-gray-900 whitespace-no-wrap">
                        <a
                          key={inx}
                          className="block mb-1"
                          href={`tel:${item?.phoneNumber}`}
                        >
                          <Tag color="#55acee">{item?.phoneNumber}</Tag>
                        </a>
                      </p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-center">
                      <p className="text-gray-900 whitespace-no-wrap">
                        {item?.aboutMe}
                        {/* <Tag color="#3b5999">{item?.state}</Tag> */}
                      </p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-center">
                      <p className="text-gray-900 whitespace-no-wrap">
                        <Switch
                          className="bg-gray-400"
                          defaultChecked={!item?.enabled}
                          checked={!item?.enabled}
                          onChange={(e) => isBlocked(item?.id, e.valueOf())}
                        />
                      </p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-center">
                      <div className="flex ">
                        <EyeOutlined
                          onClick={() => showDrawer(item)}
                          className="text-green-500 text-2xl cursor-pointer hover:text-green-600 hover:transition-all"
                        />
                        {/* <DeleteOutlined className="text-red-500 text-2xl mr-2 cursor-pointer hover:text-red-600 hover:transition-all" /> */}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="flex justify-center">
          <Pagination
            current={current}
            onChange={onChange}
            total={totalCount}
          />
        </div>
      </div>
      <Drawer
        title="Company view"
        placement="right"
        onClose={onClose}
        open={open}
        width={600}
      >
        <CompanyDataView item={viewData} />
      </Drawer>
    </div>
  );
};
