import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Input, Result, Upload, message } from "antd";
import ImgCrop from "antd-img-crop";
import TextArea from "antd/es/input/TextArea";
import { areaAdd, areaEdit } from "../../api/auth";
import { PlusOutlined } from "@ant-design/icons";
import "./style.css";

export const AreaEditModal = ({ setModalOptions, modalOptions }) => {
  console.log(
    "🚀 ~ file: index.jsx:10 ~ AreaEditModal ~ modalOptions:",
    modalOptions
  );
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [uploadImage, setUploadImage] = useState(null);
  const img = useRef();

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(modalOptions?.id?.name);
    setImage(modalOptions?.id?.photo?.pathFile);
    setLoading(false);
  }, [modalOptions]);

  const handleChange = function loadFile(e) {
    if (e.target.files.length > 0) {
      const file = URL.createObjectURL(e.target.files[0]);
      setImage(file);
      setUploadImage(e.target.files[0]);
    }
  };

  const onFinish = (values) => {
    setLoading(true);
    let value = new FormData();
    for (const property in values) {
      value.append(property, values[property]);
    }
    uploadImage === null ? console.log("") : value.append("photo", uploadImage);

    modalOptions?.type === "edit"
      ? areaEdit(modalOptions?.id?.id, value)
          .then((res) => {
            console.log(res?.data);
            setLoading(false);
            message.success(res?.data?.message);
            form.resetFields();
            setModalOptions({
              type: "",
              open: false,
              id: {},
            });
          })
          .catch((err) => console.log(err))
      : areaAdd(value)
          .then((res) => {
            console.log(res?.data);
            setLoading(false);
            message.success(res?.data?.message);
            form.resetFields();
            setModalOptions({
              type: "",
              open: false,
              id: {},
            });
          })
          .catch((err) => console.log(err));
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div>
      <Form
        name="Area"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout="vertical"
        form={form}
      >
        <Form.Item
          label="Name (uz)"
          name={"uz"}
          rules={[
            {
              required: true,
              message: "Please input your username!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Name (ru)"
          name={"ru"}
          rules={[
            {
              required: true,
              message: "Please input your username!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Name (en)"
          name={"en"}
          rules={[
            {
              required: true,
              message: "Please input your username!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <div class="text-center">
          <div class="preview_img">
            <div class="main_img">
              <picture>
                <img
                  src={
                    modalOptions?.type === "edit"
                      ? "https://onecruit.uz/api/storage/files/" +
                        modalOptions?.id?.photo?.pathFile
                      : image
                  }
                  id="preview"
                  class="preview"
                  alt="Not Image Selected"
                />
              </picture>
            </div>
          </div>
          <div className="text-left ">
            <label
              class="imgUploadCss bg-blue-700 w-75 text-center"
              id="imgLabel"
              for="imgUpload"
            >
              <i class="fa-solid fa-cloud-arrow-up pr-5"></i> Choose Image
            </label>
            <input
              className="opacity-0 imgHidden"
              id="imgUpload"
              type="file"
              ref={img}
              onChange={handleChange}
            />
          </div>
        </div>
        <Form.Item className="flex justify-end">
          <Button
            className="bg-blue-500 text-white hover:bg-blue-800 hover:text-white"
            htmlType="submit"
            type="primary"
            loading={loading}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
