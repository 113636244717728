import axios from "axios";
import { ACCESS_TOKEN } from "../constants";

const baseUrl = process.env.REACT_APP_BASE_API;

const instance = axios.create();

instance.defaults.baseURL = baseUrl;
// instance.defaults.headers["Content-Type"] = "multipart/form-data";

localStorage.getItem(ACCESS_TOKEN);

const onFullFilledReq = (config) => {
  config.headers = {
    ...config.headers,
    "ACCEPT-LANGUAGE": localStorage.getItem("lng") || "ru",
  };
  if (localStorage.getItem(ACCESS_TOKEN)) {
    config.headers = {
      ...config.headers,
      Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
    };
  }
  return config;
};

const onRejectReq = (error) => {
  return Promise.reject(error);
};

const onFullFilledRes = (response) => {
  return response;
};

const onRejectedRes = (error) => {
  return Promise.reject(error);
};

instance.interceptors.request.use(onFullFilledReq, onRejectReq);
instance.interceptors.response.use(onFullFilledRes, onRejectedRes);

export default instance;
