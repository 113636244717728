import { SET_ALL_DATA, SET_IS_AUTH, SET_USER_DATA } from "../actionTypes";

const initial_state = {
  isAuth: false,
  companyId: undefined,
  userData: null,
  // permissions: ["company_index", "job_index", "internship_index"],
};

export const authReducer = (state = initial_state, action) => {
  switch (action.type) {
    case SET_USER_DATA:
      return {
        ...state,
        userData: action.payload,
      };
    case SET_ALL_DATA:
      return {
        isAuth: action.payload.isAuth,
        companyId: action?.payload?.data?.id,
        userData: action?.payload?.data,
        // permissions: action?.payload?.data?.permissions ?? [
        //   "company_index",
        //   "job_index",
        // ],
      };
    case SET_IS_AUTH:
      return {
        ...state,
        isAuth: action.payload,
      };
    default:
      return { ...state };
  }
};
