import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Input, Result, Select, Upload, message } from "antd";
import ImgCrop from "antd-img-crop";
import TextArea from "antd/es/input/TextArea";
import {
  areaAdd,
  areaEdit,
  getAreasAll,
  skillAdd,
  skillEdit,
} from "../../api/auth";
import { PlusOutlined } from "@ant-design/icons";
import "./style.css";

export const SkillEditModal = ({ setModalOptions, modalOptions }) => {
  console.log(
    "🚀 ~ file: index.jsx:10 ~ AreaEditModal ~ modalOptions:",
    modalOptions
  );
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [areaIds, setareaIds] = useState(null);
  const [uploadImage, setUploadImage] = useState(null);
  const [areaLists, setAreaLists] = useState([]);
  const [options, setOptions] = useState([]);
  const img = useRef();

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(modalOptions?.id?.name);
    setareaIds("");
    setareaIds(modalOptions?.id?.area?.id);
    setLoading(false);
  }, [modalOptions]);

  const onChange = (value) => {
    console.log(`selected ${value}`);
    setareaIds(value);
  };
  const onSearch = (value) => {
    console.log("search:", value);
  };

  useEffect(() => {
    console.log("aaaaaaaaaaaaaaaa");
    (async () => {
      try {
        let res = await getAreasAll();
        console.log("sssssssssssssssssss", res?.data?.data?.items);
        setAreaLists(res?.data?.data?.items);
        setOptions([]);
        let data = res?.data?.data?.items;
        console.log("🚀 ~ file: index.jsx:53 ~ data:", data);
        for (let i = 0; i < data.length; i++) {
          setOptions((prevState) => [
            ...prevState,
            {
              value: data?.[i].id,
              label: data?.[i]?.name.en,
            },
          ]);
        }
      } catch (e) {
        console.log(e);
      }
    })();
  }, [modalOptions]);

  const onFinish = (values) => {
    setLoading(true);
    console.log(values);
    values.areaId = values.areaId ? values.areaId : areaIds;

    modalOptions?.type === "edit"
      ? skillEdit(modalOptions?.id?.id, values)
          .then((res) => {
            console.log(res?.data);
            setLoading(false);
            message.success(res?.data?.message);
            form.resetFields();
            setModalOptions({
              type: "",
              open: false,
              id: {},
            });
          })
          .catch((err) => console.log(err))
      : skillAdd(values)
          .then((res) => {
            console.log(res?.data);
            setLoading(false);
            message.success(res?.data?.message);
            form.resetFields();
            setModalOptions({
              type: "",
              open: false,
              id: {},
            });
          })
          .catch((err) => console.log(err));
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div>
      <Form
        name="Area"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout="vertical"
        form={form}
      >
        <Form.Item
          label="Name (uz)"
          name={"uz"}
          rules={[
            {
              required: true,
              message: "Please input your username!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Name (ru)"
          name={"ru"}
          rules={[
            {
              required: true,
              message: "Please input your username!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Name (en)"
          name={"en"}
          rules={[
            {
              required: true,
              message: "Please input your username!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Area"
          name={"areaId"}
          // rules={[
          //   {
          //     required: true,
          //     message: "Required!",
          //   },
          // ]}
        >
          <Select
            style={{ width: "100%" }}
            showSearch
            placeholder="Select a person"
            optionFilterProp="children"
            onChange={onChange}
            onSearch={onSearch}
            defaultValue={areaIds}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={options}
          />
        </Form.Item>
        <Form.Item className="flex justify-end">
          <Button
            className="bg-blue-500 text-white hover:bg-blue-800 hover:text-white"
            htmlType="submit"
            type="primary"
            loading={loading}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
