import React, { useEffect, useState } from "react";
import {
  companyBlock,
  getAllComp,
  getAllJobs,
  getJobById,
  vacanyBlock,
} from "../../api/auth";
import {
  Col,
  Drawer,
  Empty,
  Form,
  Input,
  Pagination,
  Row,
  Select,
  Spin,
  Switch,
  Tag,
  message,
} from "antd";
import dayjs from "dayjs";
import { EyeOutlined } from "@ant-design/icons";
const { Option } = Select;
const { Search } = Input;

export const Internships = () => {
  const [jobList, setJobList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [jobMeta, setJobMeta] = useState();
  // Filts - Start
  const [type, setType] = useState("INTERNSHIP");

  // Filts - End
  const [update, setUpdate] = useState(false);
  const [checked, setChecked] = useState(Boolean);
  const [requireds, setRequireds] = useState({
    minSalary: false,
    maxSalary: false,
    yearOfExperience: false,
    search: false,
  });

  const [jobViewData, setJobViewData] = useState({});
  const [companiesList, setCompaniesList] = useState([]);
  const [open, setOpen] = useState(false);
  const [params, setParams] = useState({
    page: 1,
    size: 10,
    type,
  });

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const res = await getAllJobs(params);
        const data = res?.data?.data;

        setJobList(data?.items);
        setJobMeta(data?.meta);
        console.log("🚀 ~ file: index.jsx:15 ~ data:", data);
      } catch (error) {
        console.log("🚀 ~ file: index.jsx:11 ~ error:", error);
      } finally {
        setLoading(false);
      }
    })();
  }, [params]);

  useEffect(() => {
    (async () => {
      try {
        const res = await getAllComp();
        const data = res?.data?.data;

        const newData = data?.map((item) => ({
          value: item?.id,
          label: item?.name,
        }));
        setCompaniesList(newData);
      } catch (error) {
        console.log("🚀 ~ error:", error);
      }
    })();
  }, []);

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const onFinish = async (values) => {
    console.log("🚀 ~ file: index.jsx:57 ~ onFinish ~ values:", values);
  };

  const handlerPag = (e) => {
    setParams((prevState) => ({
      ...prevState,
      page: e,
    }));
  };

  const getJobByIdFunc = async (id) => {
    try {
      const res = await getJobById(id, type);
      const data = res?.data?.data;
      setJobViewData(data);
      console.log("🚀 ~ file: index.jsx:72 ~ getJobById ~ data:", data);
    } catch (error) {
      console.log("🚀 ~ file: index.jsx:72 ~ getJobById ~ error:", error);
    }
  };

  const showDrawer = (id) => {
    setOpen(true);
    // setJobId(id);
    getJobByIdFunc(id);
  };

  const onClose = () => {
    setOpen(false);
  };

  const isBlocked = async (id, checked, inx) => {
    try {
      setLoading(true);
      const res = await vacanyBlock(id, checked);
      jobList[inx].state = checked ? "ARCHIVE" : "ACTIVE";
      message.success(res?.data?.message);
    } catch (error) {
      message.error(error?.data?.message);
      console.log("🚀 ~ file: index.jsx:100 ~ isBlocked ~ error:", error);
    } finally {
      setLoading(false);
      setChecked(checked);
    }
  };

  const handleChangeJobState = (value) => {
    setParams((oldState) => ({
      ...oldState,
      state: value ? value : null,
    }));
  };

  const handlerMinSal = (value) => {
    if (value) {
      setParams((oldState) => ({
        ...oldState,
        minSalary: value ? value : null,
      }));
    } else {
      setRequireds((prevState) => ({
        ...prevState,
        minSalary: true,
      }));
    }
  };
  const onChangeMinSal = (e) => {
    const value = e.target.value;
    if (!value) {
      setParams((oldState) => ({
        ...oldState,
        minSalary: null,
      }));
    } else {
      setRequireds((prevState) => ({
        ...prevState,
        minSalary: false,
      }));
    }
  };

  const handlerMaxSal = (value) => {
    if (value) {
      setParams((oldState) => ({
        ...oldState,
        maxSalary: value ? value : null,
      }));
    } else {
      setRequireds((prevState) => ({
        ...prevState,
        maxSalary: true,
      }));
    }
  };
  const onChangeMaxSal = (e) => {
    const value = e.target.value;
    if (!value) {
      setParams((oldState) => ({
        ...oldState,
        maxSalary: null,
      }));
    } else {
      setRequireds((prevState) => ({
        ...prevState,
        maxSalary: false,
      }));
    }
  };

  const handlerYearExp = (value) => {
    if (value) {
      setParams((oldState) => ({
        ...oldState,
        yearOfExperience: value ? value : null,
      }));
    } else {
      setRequireds((prevState) => ({
        ...prevState,
        yearOfExperience: true,
      }));
    }
  };
  const onChangeYearExp = (e) => {
    const value = e.target.value;
    if (!value) {
      setParams((oldState) => ({
        ...oldState,
        yearOfExperience: null,
      }));
    } else {
      setRequireds((prevState) => ({
        ...prevState,
        yearOfExperience: false,
      }));
    }
  };

  const handleChangeJobType = (value) => {
    setParams((oldState) => ({
      ...oldState,
      jobType: value ? value : null,
    }));
  };
  const handleChangeCompany = (value) => {
    setParams((oldState) => ({
      ...oldState,
      company_id: value ? value : null,
    }));
  };

  const onSearch = (value) => {
    if (value) {
      setParams((oldState) => ({
        ...oldState,
        searchAny: value,
        page: 1,
      }));
    } else {
      setRequireds((prevState) => ({
        ...prevState,
        search: true,
      }));
    }
  };

  const onSearchChange = (e) => {
    const value = e?.target?.value;

    if (!value) {
      setParams((oldState) => ({
        ...oldState,
        searchAny: null,
      }));
    } else {
      setRequireds((prevState) => ({
        ...prevState,
        search: false,
      }));
    }
  };

  const onKeyDown = (e, type) => {
    const keyCode = e.keyCode;
    const value = e.target.value;

    if (keyCode === 13) {
      switch (type) {
        case "minSalary":
          handlerMinSal(value);
          break;
        case "maxSalary":
          handlerMaxSal(value);
          break;
        case "yearOfExperience":
          handlerYearExp(value);
          break;

        default:
          break;
      }
    }
  };

  return (
    <div>
      <div>
        <h2 className="text-2xl font-semibold leading-tight">Internships</h2>
      </div>
      <div>
        <h2 className="text-2xl font-semibold leading-tight text-center">
          Filters
        </h2>
      </div>
      <Form layout="vertical" onFinish={onFinish}>
        <Row>
          {/* State */}
          <Col span={2}>
            <Form.Item label="State" name={"state"}>
              <Select
                defaultValue=""
                style={{ width: 120 }}
                onChange={handleChangeJobState}
                options={[
                  { value: "", label: "All" },
                  { value: "ACTIVE", label: "Active" },
                  { value: "ARCHIVE", label: "Archive" },
                  { value: "DRAFT", label: "Draft" },
                ]}
              />
            </Form.Item>
          </Col>

          {/* Min salary */}
          <Col span={2}>
            <Form.Item
              label="Min salary"
              name="minSal"
              help={requireds.minSalary ? "Please fill the field!" : ""}
              validateStatus={requireds.minSalary ? "warning" : ""}
            >
              <Input
                className="[&::-webkit-inner-spin-button]:appearance-none"
                placeholder="$100"
                type="number"
                onBlur={() =>
                  setRequireds((prevState) => ({
                    ...prevState,
                    minSalary: false,
                  }))
                }
                onKeyDown={(e) => onKeyDown(e, "minSalary")}
                onChange={onChangeMinSal}
                style={{
                  width: 100,
                }}
              />
            </Form.Item>
          </Col>

          {/* Max salary */}
          <Col span={2}>
            <Form.Item
              label="Max salary"
              name="maxSal"
              help={requireds.maxSalary ? "Please fill the field!" : ""}
              validateStatus={requireds.maxSalary ? "warning" : ""}
            >
              <Input
                className="[&::-webkit-inner-spin-button]:appearance-none"
                placeholder="$500"
                type="number"
                onKeyDown={(e) => onKeyDown(e, "maxSalary")}
                onBlur={() =>
                  setRequireds((prevState) => ({
                    ...prevState,
                    maxSalary: false,
                  }))
                }
                onChange={onChangeMaxSal}
                style={{
                  width: 100,
                }}
              />
            </Form.Item>
          </Col>

          {/*Year of experience */}
          <Col span={2}>
            <Form.Item
              label="Year of experience"
              name="experience"
              help={requireds.yearOfExperience ? "Please fill the field!" : ""}
              validateStatus={requireds.yearOfExperience ? "warning" : ""}
            >
              <Input
                className="[&::-webkit-inner-spin-button]:appearance-none"
                placeholder="1"
                type="number"
                onKeyDown={(e) => onKeyDown(e, "yearOfExperience")}
                onChange={onChangeYearExp}
                onBlur={() =>
                  setRequireds((prevState) => ({
                    ...prevState,
                    yearOfExperience: false,
                  }))
                }
                style={{
                  width: 100,
                }}
              />
            </Form.Item>
          </Col>

          {/* Job type */}
          <Col span={2}>
            <Form.Item label="Job type" name={"jobType"}>
              <Select
                defaultValue=""
                style={{ width: 100 }}
                onChange={handleChangeJobType}
                options={[
                  { value: "", label: "All" },
                  { value: "Hybrid", label: "Hybrid" },
                  { value: "Office", label: "Office" },
                  { value: "Online", label: "Online" },
                ]}
              />
            </Form.Item>
          </Col>

          {/* Company */}
          <Col span={2}>
            <Form.Item
              label="Companies"
              name={"jobCompany"}
              style={{ width: 300 }}
            >
              <Select
                allowClear
                showSearch
                filterOption={filterOption}
                defaultValue=""
                optionFilterProp="children"
                style={{ width: 150 }}
                onChange={handleChangeCompany}
                options={companiesList}
              />
            </Form.Item>
          </Col>

          {/* Search */}
          <Col span={12}>
            <Form.Item
              label="Search"
              name={"searchAny"}
              style={{ display: "flex", justifyContent: "end" }}
              help={requireds.search ? "Please fill the field!" : ""}
              validateStatus={requireds.search ? "warning" : ""}
            >
              <Search
                style={{ width: 250 }}
                placeholder="Pleace enter internship name"
                onSearch={onSearch}
                onChange={(e) => onSearchChange(e)}
                onBlur={() =>
                  setRequireds((prevState) => ({
                    ...prevState,
                    search: false,
                  }))
                }
                enterButton
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div className="py-4 overflow-x-auto">
        <div className="inline-block min-w-full shadow-md rounded-lg overflow-hidden">
          <Spin spinning={loading}>
            <table className="min-w-full leading-normal">
              <thead>
                <tr>
                  <th className="py-3 px-5 text-left border-b-2 border-gray-200 bg-gray-100">
                    №
                  </th>
                  <th className="py-3 px-5 text-left border-b-2 border-gray-200 bg-gray-100">
                    Name
                  </th>
                  <th className="py-3 px-5 text-left border-b-2 border-gray-200 bg-gray-100">
                    Industry
                  </th>
                  <th className="py-3 px-5 text-left border-b-2 border-gray-200 bg-gray-100">
                    Min salary
                  </th>
                  <th className="py-3 px-5 text-left border-b-2 border-gray-200 bg-gray-100">
                    Due date
                  </th>
                  <th className="py-3 px-5 text-left border-b-2 border-gray-200 bg-gray-100">
                    Job type
                  </th>
                  <th className="py-3 px-5 text-left border-b-2 border-gray-200 bg-gray-100">
                    Company name
                  </th>
                  <th className="py-3 px-5 text-left border-b-2 border-gray-200 bg-gray-100">
                    State
                  </th>
                  <th className="py-3 px-5 text-left border-b-2 border-gray-200 bg-gray-100">
                    Is archive
                  </th>
                  <th className="py-3 px-5 text-left border-b-2 border-gray-200 bg-gray-100">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {jobList?.length ? (
                  jobList?.map((item, inx) => (
                    <tr key={inx}>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {params.page === 1
                            ? inx + 1
                            : params.page * 10 - 10 + inx + 1}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap truncateW100">
                          {item?.name}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {item?.industry}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {item?.minimumSalary}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {dayjs(item?.dueDate).format("DD-MM-YYYY")}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {item?.jobType}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {item?.company?.name}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          <Tag color="#3b5999">{item?.state}</Tag>
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          <Switch
                            className="bg-gray-400"
                            defaultChecked={
                              item?.state === "ARCHIVE" ? true : false
                            }
                            checked={item?.state === "ARCHIVE" ? true : false}
                            onChange={(e) =>
                              isBlocked(item?.id, e.valueOf(), inx)
                            }
                          />
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <div className="flex ">
                          <EyeOutlined
                            onClick={() => showDrawer(item?.id)}
                            className="text-green-500 text-2xl cursor-pointer hover:text-green-600 hover:transition-all"
                          />
                          {/* <DeleteOutlined className="text-red-500 text-2xl mr-2 cursor-pointer hover:text-red-600 hover:transition-all" /> */}
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <Empty
                    className="py-10"
                    style={{
                      position: "sticky",
                      left: "50%",
                      transform: "translateX(-50%)",
                    }}
                  />
                )}
              </tbody>
            </table>
          </Spin>
        </div>
      </div>
      {/* Pagination */}
      {jobList?.length ? (
        <div className="flex justify-center">
          <Pagination
            current={params.page}
            total={jobMeta?.totalCount}
            pageSize={jobMeta?.size}
            onChange={handlerPag}
          />
        </div>
      ) : (
        ""
      )}

      <Drawer
        title="Job details"
        placement="right"
        onClose={onClose}
        open={open}
        width={600}
      >
        <Row gutter={24}>
          <Col sm={24}>
            <div className="py-4">
              <h3 className="text-xl font-medium">Name</h3>
              <p className="text-gray-900 whitespace-no-wrap">
                {jobViewData?.name}
              </p>
            </div>
          </Col>
          <Col sm={24}>
            <div className="py-4">
              <h3 className="text-xl font-medium">Description</h3>
              <p className="text-gray-900 whitespace-no-wrap">
                {jobViewData?.description}
              </p>
            </div>
          </Col>
          <Col sm={24}>
            <div className="py-4">
              <h3 className="text-xl font-medium">Area</h3>
              <p className="text-gray-900 whitespace-no-wrap">
                {jobViewData?.area}
              </p>
            </div>
          </Col>
          <Col sm={12}>
            <div className="py-4">
              <h3 className="text-xl font-medium">Due date</h3>
              <p className="text-gray-900 whitespace-no-wrap">
                {jobViewData?.dueDate}
              </p>
            </div>
          </Col>
          <Col sm={12}>
            <div className="py-4">
              <h3 className="text-xl font-medium">Industry</h3>
              <p className="text-gray-900 whitespace-no-wrap">
                {jobViewData?.industry}
              </p>
            </div>
          </Col>
          <Col sm={12}>
            <div className="py-4">
              <h3 className="text-xl font-medium">Job type</h3>
              <p className="text-gray-900 whitespace-no-wrap">
                {jobViewData?.jobType}
              </p>
            </div>
          </Col>
          <Col sm={12}>
            <div className="py-4">
              <h3 className="text-xl font-medium">Minimum salary</h3>
              <p className="text-gray-900 whitespace-no-wrap">
                {jobViewData?.minimumSalary}
              </p>
            </div>
          </Col>
          <Col sm={12}>
            <div className="py-4">
              <h3 className="text-xl font-medium">Status</h3>
              <p className="text-gray-900 whitespace-no-wrap">
                <Tag color="#3b5999">{jobViewData?.state}</Tag>
              </p>
            </div>
          </Col>
          <Col sm={12}>
            <div className="py-4">
              <h3 className="text-xl font-medium">Register date</h3>
              <p className="text-gray-900 whitespace-no-wrap">
                {new Date(jobViewData?.createdAt)?.toLocaleString("en-GB", {
                  hour12: false,
                })}
              </p>
            </div>
          </Col>
          <Col sm={12}>
            <div className="py-4">
              <h3 className="text-xl font-medium">Viewed amount</h3>
              <p className="text-gray-900 whitespace-no-wrap">
                <Tag color="#3b5999">{jobViewData?.viewedAmount}</Tag>
              </p>
            </div>
          </Col>
          <Col sm={12}>
            <div className="py-4">
              <h3 className="text-xl font-medium">Year of experience</h3>
              <p className="text-gray-900 whitespace-no-wrap">
                <Tag color="#3b5999">{jobViewData?.yearOfExperience}</Tag>
              </p>
            </div>
          </Col>

          <Col sm={24}>
            <div className="py-4">
              <h3 className="text-xl font-medium">Comapany name</h3>
              <p className="text-gray-900 whitespace-no-wrap">
                {jobViewData?.company?.name}
              </p>
            </div>
          </Col>
        </Row>
      </Drawer>
    </div>
  );
};
