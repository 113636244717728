import React, { useEffect } from "react";
import "./style.css";
import { Button, Checkbox, Form, Input, message } from "antd";
import { useNavigate } from "react-router-dom";
import { postLogin } from "../../api/auth";
import { ACCESS_TOKEN } from "../../constants";

export const Login = () => {
  const navigate = useNavigate();
  // useEffect(() => {
  //   if (localStorage.getItem(ACCESS_TOKEN)) {
  //     navigate("/admin");
  //   }
  // });
  const onFinish = (values) => {
    let res = postLogin(values)
      .then((res) => {
        localStorage.setItem(ACCESS_TOKEN, res?.data?.data?.token);
        navigate("/admin");
        message.success(res?.data?.message);
      })
      .catch((err) => message.error(err?.response?.data?.message));
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <div className="main-contend-section">
      <div className="main-container">
        <div className="w-50">
          <div className="logo">
            <h1 className="text-6xl text-blue-600 font-bold ml-12 py-3">
              OneCruit
            </h1>
          </div>
          <div className="fb-tag-line">
            <h3 id="tnk">
              OneCruit helps you connect and share with the people in your life.
            </h3>
          </div>
        </div>
        <div className="w-50 p-4">
          <div className="form-section p-4">
            <Form
              className="py-3"
              name="basic"
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 16,
              }}
              style={{
                maxWidth: 600,
              }}
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label="Username"
                name="login"
                rules={[
                  {
                    required: true,
                    message: "Please input your username!",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item
                wrapperCol={{
                  offset: 8,
                  span: 16,
                }}
              >
                <Button
                  className="bg-blue-700"
                  type="primary"
                  htmlType="submit"
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};
