import { useEffect, useState } from "react";
import { aboutMe } from "./api/auth";
import { ACCESS_TOKEN } from "./constants";
import { Route, Routes, useNavigate } from "react-router-dom";
import { SET_ALL_DATA } from "./redux/actionTypes";
import { useDispatch } from "react-redux";
import { Dashboard } from "./pages/dashboard";
import { Company } from "./pages/company";
import { Login } from "./auth/login";
import Main from "./layout";
import { Users } from "./pages/users";
import { Jobs } from "./pages/jobs";
import { Internships } from "./pages/internships";
import { Areas } from "./pages/areas";
import { Students } from "./pages/students";
import { Appeals } from "./pages/appeals";
import { Skills } from "./pages/skills";

function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    (async () => {
      let token = localStorage.getItem(ACCESS_TOKEN);
      if (token) {
        try {
          let resp = await aboutMe();
          // console.log("🚀 ~ file: App.jsx:60 ~ resp:", resp)
          if (resp.data?.dataStatus === 1) {
            dispatch({
              type: SET_ALL_DATA,
              payload: { isAuth: true, data: resp.data?.data },
            });
          } else {
            // message.error(resp.data?.message);
            navigate(`/`);
            localStorage.removeItem(ACCESS_TOKEN);
          }
          setLoading(false);
        } catch (error) {
          // message.error(
          //   error?.response ? error?.response?.data?.message : error?.message
          // );
          localStorage.removeItem(ACCESS_TOKEN);
          navigate(`/`);
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/admin" element={<Main />}>
        <Route index element={<Dashboard />} />
        <Route path="companies" element={<Company />} />
        <Route path="users" element={<Users />} />
        <Route path="jobs" element={<Jobs />} />
        <Route path="internships" element={<Internships />} />
        <Route path="students" element={<Students />} />
        <Route path="areas" element={<Areas />} />
        <Route path="skills" element={<Skills />} />
        <Route path="appeals" element={<Appeals />} />
      </Route>
    </Routes>
  );
}

export default App;
