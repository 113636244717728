import React, { useEffect, useState } from "react";
import {
  Button,
  Empty,
  Modal,
  Pagination,
  Spin,
  Switch,
  Table,
  Tag,
  message,
} from "antd";
import { AiOutlinePhone, AiOutlineUser } from "react-icons/ai";
import { HiOutlineBuildingLibrary, HiShoppingBag } from "react-icons/hi2";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
  FieldTimeOutlined,
} from "@ant-design/icons";
import { RejectModal } from "../../components/rejectModal";
import {
  areaArchive,
  areaDelete,
  companyBlock,
  getAreasAll,
} from "../../api/auth";
import { AreaEditModal } from "../../components/areaEditModal";
import "./style.css";

export const Areas = () => {
  const [open, setOpen] = useState(false);
  const [modalOptions, setModalOptions] = useState({
    type: "",
    open: open,
    id: {},
  });
  const [update, setUpdate] = useState(false);
  const [statistics, setStatistics] = useState({});
  const [areasList, setAreasList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [checked, setChecked] = useState(Boolean);
  const [params, setParams] = useState({
    page: 1,
    size: 10,
  });
  const [meta, setMeta] = useState();

  const [current, setCurrent] = useState(1);
  const onChange = (page) => {
    console.log(page);
    setCurrent(page);
  };
  const showModal = (type, open, id) => {
    console.log("🚀 ~ file: index.jsx:21 ~ showModal ~ id:", id);
    setOpen(true);
    setModalOptions({
      type,
      open,
      id,
    });
  };
  const handleOk = () => {
    // setOpen(false);
  };
  const handleCancel = () => {
    setModalOptions({
      type: "",
      open: false,
      id: {},
    });
  };
  const isArchived = (id, checked) => {
    console.log(`switch to ${checked}`);
    areaArchive(id, checked)
      .then((res) => {
        message.success(res?.data?.message);
        console.log(res.data);
        setUpdate((prev) => !prev);
      })
      .catch((err) => {
        message.error(err?.data?.message);
        console.log(err);
      });
    setChecked(checked);
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const res = await getAreasAll(params);
        setAreasList(res?.data?.data?.items);

        setMeta(res?.data?.data?.meta);
      } catch (error) {
        console.log("🚀 ~ file: index.jsx:89 ~ error:", error);
      } finally {
        setLoading(false);
      }
    })();
  }, [modalOptions, update, params]);

  const showConfirm = (id) => {
    console.log("🚀 ~ file: index.jsx:78 ~ showConfirm ~ id:", id);
    Modal.confirm({
      title: "Do you Want to delete these items?",
      icon: <ExclamationCircleFilled />,
      content: "Some descriptions",
      onOk() {
        areaDelete(id)
          .then((res) => {
            console.log(res.data);
            setUpdate((prev) => !prev);
            message.success(res?.data?.message);
          })
          .catch((err) => console.log(err));
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const handlerPag = (e) => {
    setParams((prevState) => ({
      ...prevState,
      page: e,
    }));
  };
  return (
    <>
      <div>
        <div className="flex justify-between">
          <h2 className="text-2xl font-semibold leading-tight">Areas</h2>
          <Button type="primary" onClick={(e) => showModal("add", true)}>
            + Add
          </Button>
        </div>
        <div className="py-4 overflow-x-auto">
          <div className="inline-block min-w-full shadow-md rounded-lg overflow-hidden">
            <Spin spinning={loading}>
              <table className="min-w-full leading-normal">
                <thead>
                  <tr>
                    <th className="py-3 px-5 text-left border-b-2 border-gray-200 bg-gray-100">
                      №
                    </th>
                    <th className="py-3 px-5 text-left border-b-2 border-gray-200 bg-gray-100">
                      Icon
                    </th>
                    <th className="py-3 px-5 text-left border-b-2 border-gray-200 bg-gray-100">
                      Name(uz)
                    </th>
                    <th className="py-3 px-5 text-left border-b-2 border-gray-200 bg-gray-100">
                      Name(ru)
                    </th>
                    <th className="py-3 px-5 text-left border-b-2 border-gray-200 bg-gray-100">
                      Name(en)
                    </th>
                    <th className="py-3 px-5 text-left border-b-2 border-gray-200 bg-gray-100">
                      Is archived
                    </th>
                    <th className="py-3 px-5 text-left border-b-2 border-gray-200 bg-gray-100">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="text-center">
                  {areasList?.length ? (
                    areasList?.map((item, inx) => (
                      <tr key={inx}>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <p className="text-gray-900 whitespace-no-wrap">
                            {inx + 1}
                          </p>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <div className="flex justify-center">
                            <div className="flex-shrink-0 w-10 h-10">
                              <img
                                className="w-full h-full rounded-full"
                                src={`https://onecruit.uz/api/storage/files/${item?.photo?.pathFile}`}
                                alt=""
                              />
                            </div>
                          </div>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <p className="text-gray-900 whitespace-no-wrap">
                            {item?.name?.uz}
                          </p>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <p className="text-gray-900 whitespace-no-wrap">
                            {item?.name?.ru}
                          </p>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <p className="text-gray-900 whitespace-no-wrap">
                            {item?.name?.en}
                          </p>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <p className="text-gray-900 whitespace-no-wrap">
                            <Switch
                              className="bg-gray-400"
                              defaultChecked={item?.archived}
                              checked={item?.archived}
                              onChange={(e) =>
                                isArchived(item?.id, e.valueOf())
                              }
                            />
                          </p>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <div className="flex justify-center">
                            <EditOutlined
                              onClick={(e) => showModal("edit", true, item)}
                              className="text-blue-500 text-2xl mr-2 cursor-pointer hover:text-blue-600 hover:transition-all"
                            />
                            <DeleteOutlined
                              onClick={() => showConfirm(item?.id)}
                              className="text-red-500 text-2xl cursor-pointer hover:text-red-600 hover:transition-all"
                            />
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <Empty
                      className="py-10"
                      style={{
                        position: "sticky",
                        left: "50%",
                        transform: "translateX(-50%)",
                      }}
                    />
                  )}
                </tbody>
              </table>
            </Spin>
          </div>
        </div>
        {/* Pagination */}
        {areasList?.length ? (
          <div className="flex justify-center">
            <Pagination
              current={params.page}
              total={meta?.totalCount}
              pageSize={meta?.size}
              onChange={handlerPag}
            />
          </div>
        ) : (
          ""
        )}
      </div>
      <Modal
        open={modalOptions.open}
        title={"Area"}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
      >
        <AreaEditModal
          modalOptions={modalOptions}
          setModalOptions={setModalOptions}
        />
      </Modal>
    </>
  );
};
