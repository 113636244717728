import React, { useState } from "react";
import { Button, Form, Result } from "antd";
import TextArea from "antd/es/input/TextArea";
import { confirmCompany, uconfirmCompany } from "../../api/auth";

export const RejectModal = ({
  setModalOptions,
  modalOptions,
  setCompaniesList,
  companiesList,
}) => {
  const [loading, setLoading] = useState(false);
  // const [form] = Form.useForm();
  console.log(setModalOptions, modalOptions);
  const onFinish = (values) => {
    console.log("Success:", values);
    setLoading(true);
    uconfirmCompany(modalOptions?.id, 2, values?.rejectMessage)
      .then((res) => {
        console.log(res?.data);
        setLoading(false);
        setModalOptions({
          open: false,
        });
      })
      .catch((err) => console.log(err));
  };
  const confirmFunc = (type) => {
    setLoading(true);
    confirmCompany(modalOptions?.id, type)
      .then((res) => {
        console.log(res?.data);
        setLoading(false);
        const filtered_data = companiesList?.filter(
          (item) => item.id !== modalOptions?.id
        );
        setCompaniesList(filtered_data);
        setModalOptions({
          open: false,
          id: 2,
        });
      })
      .catch((err) => console.log(err));
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <div className="pt-3">
      {modalOptions?.type === "approve" ? (
        <Result
          status="warning"
          title={
            <div>
              <h1 className="text-2xl font-medium">Approve the application?</h1>
              <p className="text-gray-500-600 text-xl">
                The approval of the application ensures the creation of the
                company and hr!
              </p>
            </div>
          }
          extra={[
            <Button
              className="bg-blue-500 text-white"
              type="primary"
              key="console"
              onClick={() => confirmFunc(1)}
              loading={loading}
            >
              Yes !
            </Button>,
            <Button
              onClick={() => setModalOptions({ open: false })}
              className="bg-red-500 text-white border-red-500 hover:border-red-500 hover:bg-red-400"
              type="ghost"
              key="console"
            >
              No !
            </Button>,
          ]}
        />
      ) : (
        <Form
          name="Reject"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
        >
          <Form.Item
            label="Reject appeal"
            name="rejectMessage"
            rules={[
              {
                required: true,
                message: "Required!",
              },
            ]}
          >
            <TextArea
              rows={6}
              maxLength={1000}
              showCount
              placeholder="Message..."
              autoSize
            />
          </Form.Item>
          <Form.Item className="flex justify-end">
            <Button
              className="bg-blue-500 text-white hover:bg-blue-800 hover:text-white"
              htmlType="submit"
              type="primary"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      )}
    </div>
  );
};
